import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { SnackbarProvider } from 'notistack';
import LocalData from './helpers/local-data';
import Config from './config';
import './App.css';
import ScrollToTop from './helpers/scroll-top';
import Bowser from 'bowser'; // Import bowser

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
    </div>
);

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unsupportedBrowser: false,
        };
    }

    componentDidMount() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const browserName = browser.getBrowserName();
        const browserVersion = browser.getBrowserVersion();

        // Define unsupported browsers and versions
        const unsupportedBrowsers = [
            { name: 'Internet Explorer' },
            { name: 'Edge', version: '<=18' },
            // Add more browsers if needed
        ];

        const isUnsupported = unsupportedBrowsers.some((b) => {
            if (b.version) {
                return (
                    browserName === b.name &&
                    browser.satisfies({ [b.name]: b.version })
                );
            }
            return browserName === b.name;
        });

        if (isUnsupported) {
            this.setState({ unsupportedBrowser: true });
        }
    }

    render() {
        // Existing token and redirect logic
        function useQuery() {
            return new URLSearchParams(window.location.search);
        }

        let query = useQuery();
        var jwt_token = query.get('jwt_token');

        if (jwt_token) {
            LocalData.save(Config.LOCAL_DATA_IDS.TOKEN, jwt_token);
            window.location.href = '/';
        }

        if (!LocalData.load(Config.LOCAL_DATA_IDS.TOKEN)) {
            window.location.href = Config.URLs.PORTAL;
        }

        // Render prompt if browser is unsupported
        if (this.state.unsupportedBrowser) {
            return (
                <div className="unsupported-browser">
                    <h1>Unsupported Browser</h1>
                    <p>
                        Your current browser is not supported. Please switch to a
                        different browser for the best experience.
                    </p>
                    <ul>
                        <li>
                            <a href="https://www.google.com/chrome/">Google Chrome</a>
                        </li>
                        <li>
                            <a href="https://www.mozilla.org/firefox/new/">Mozilla Firefox</a>
                        </li>
                        <li>
                            <a href="https://www.microsoft.com/edge">Microsoft Edge</a>
                        </li>
                    </ul>
                </div>
            );
        }

        // Render the main app if browser is supported
        return (
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={7000}
            >
                <HashRouter>
                    <React.Suspense fallback={loading}>
                        <ScrollToTop />
                        <Switch>
                            <Route
                                path="/"
                                name="Home"
                                render={(props) => <TheLayout {...props} />}
                            />
                        </Switch>
                    </React.Suspense>
                </HashRouter>
            </SnackbarProvider>
        );
    }
}

export default App;
